.app-splash-screen {
    background: rgb(51, 17, 219);
    background: linear-gradient(0deg, rgba(51, 17, 219, 1) 0%, rgba(51, 17, 219, 1) 100%); 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10000;
    opacity: 1;
}

/* rgba(117, 81, 255, 1)*/

.splash-hidden {
    opacity: 0;
    transition: all 250ms linear 0.5s;
    z-index: -1;
}

.flip {
    backface-visibility: visible !important;
    animation: flip 1s ease;
    animation-iteration-count: 1;
}

.pulse{
    animation: pulse 0.8s ease 0s 5 normal forwards;
}

.zoomout{
    backface-visibility: visible !important;
    animation: zoomout 1s ease;
    animation-iteration-count: 1;
}

@keyframes flip {
    0% {
        transform: perspective(400px) rotateY(0);
        animation-timing-function: ease-out;
    }

    40% {
        transform: perspective(400px) translateZ(150px) rotateY(170deg);
        animation-timing-function: ease-out;
    }

    50% {
        transform: perspective(400px) translateZ(150px) rotateY(200deg) scale(1);
        animation-timing-function: ease-in;
    }

    80% {
        transform: perspective(400px) rotateY(360deg) scale(0.8);
        animation-timing-function: ease-in;
    }

    100% {
        transform: perspective(400px) scale(1);
        animation-timing-function: ease-in;
    }
}

@keyframes zoomout {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    to {
        opacity: 0;
    }
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}