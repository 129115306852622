.fc .fc-col-header-cell-cushion {
    padding-top: 5px;
    padding-bottom: 5px;
}

body.chakra-ui-dark .fc .fc-day-disabled {
    background: #1B254B;
    background: #1B254B;
}

body.chakra-ui-dark .fc table {
    border-radius: 20px;
    border-color: gray;
    border-left-color: gray;
}

body.chakra-ui-dark .fc th {
    border-radius: 20px;
    border-bottom-right-radius: 0px;
    border-color: gray;
}

body.chakra-ui-dark .fc td {
    border-color: gray;
}

body.chakra-ui-dark .fc td:last-child {
    border-bottom-right-radius: 20px;
    border-color: gray;
}

body.chakra-ui-dark .fc td:first-child {
    border-bottom-left-radius: 20px;
    border-color: gray;
}

.fc .fc-daygrid-day {
    overflow: hidden;
}

body.chakra-ui-dark .fc .fc-daygrid-day {
    overflow: hidden;
}

body.chakra-ui-dark .fc-daygrid-week-number {
    background-color: #1B254B !important;
    color: gray !important;
}

body.chakra-ui-dark .fc-daygrid-day.non-current-month {
    background-color: rgba(255, 255, 255, 0.1)
}

body.chakra-ui-light .fc-daygrid-day.non-current-month {
    background-color: rgba(230, 230, 230, 0.8)
}

.fc .fc-day-disabled {
    background: #f9faf9;
    background: #f9faf9;
}

.fc table {
    border-radius: 20px;
}

.fc th {
    border-radius: 20px;
    border-bottom-right-radius: 0px;
}


.fc td:last-child {
    border-bottom-right-radius: 20px;

}

.fc td:first-child {
    border-bottom-left-radius: 20px;

}

.fc .fc-event {
    margin-bottom: 5px;
}