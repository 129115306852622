.quill>* {
  border-color: inherit !important;
  color: inherit !important;
}

.quill>.ql-toolbar {
  /* border radius of the toolbar */
  border-radius: 20px 20px 0 0;
}

.quill>.ql-container {
  /* border radius of the container and for font size*/
  min-height: 200px;
  font-size: inherit;
  font-family: "DM Sans";
  border-radius: 0 0 20px 20px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  color: inherit !important;
  opacity: 0.76;
}

.ql-snow .ql-picker {
  color: inherit !important;
}

.quill>.ql-container>.ql-editor.ql-blank::before {
  /* for placeholder */
  color: lightslategray;
}

.ql-snow.ql-toolbar button svg {
  opacity: 0.76;
  color: currentColor;
}

.ql-snow .ql-stroke {
  /* for the border of the editor */
  stroke: currentColor !important;
}

.ql-snow .ql-fill {
  /* for the bg color */
  fill: currentColor !important;
}

.ql-picker-item {
  /* for dropdown */
  color: #444 !important;
}